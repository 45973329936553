import type { QueryHookOptions, MutationHookOptions, OperationVariables } from '@apollo/client';
import { stampQueuedActionOptionsOnContext } from 'owa-apollo';

export const createManagedQueryOptions = <TData, TVariables extends OperationVariables>(
    options?: QueryHookOptions<TData, TVariables>
): QueryHookOptions<TData, TVariables> => {
    const tmpOptions = options || {};
    const tmpContext = tmpOptions.context || {};

    tmpOptions.fetchPolicy = tmpOptions.fetchPolicy || 'cache-first';
    return { ...tmpOptions, context: tmpContext };
};

export const createManagedMutationOptions = <TData, TVariables>(
    options?: MutationHookOptions<TData, TVariables>
): MutationHookOptions<TData, TVariables> => {
    const tmpOptions = stampQueuedActionOptionsOnContext(options || {});
    const tmpContext = tmpOptions.context || {};

    return { ...tmpOptions, context: tmpContext };
};
